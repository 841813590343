import { Box, IconButton, useTheme } from '@mui/material'
import React, { useContext, useState } from 'react'
import { tokens } from '../theme';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import LogoutIcon from '@mui/icons-material/Logout';
import { AdminContext } from '../context/AdminContext';
// import NotificationDialog from '../components/NotificationDialog';
// import SettingsDialog from '../components/SettingsDialog';

function Topbar() {
    const { setToken, admin } = useContext(AdminContext);

    const [notificationDialogOpen, setNotificationDialogOpen] = useState(false);
    const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);

    return (
        <Box position="absolute" right="0" display="flex" gap="10px" pt="10px" pr="20px" justifyContent="end" alignItems="center" zIndex="5">
            {/* <NotificationDialog dialogOpen={notificationDialogOpen} handleClose={() => setNotificationDialogOpen(false)} /> */}
            {/* <SettingsDialog dialogOpen={settingsDialogOpen} handleClose={() => setSettingsDialogOpen(false)} /> */}
            {admin.notifications_perm === 1 &&
                <IconButton onClick={() => setNotificationDialogOpen(true)}>
                    <NotificationAddIcon />
                </IconButton>
            }
            {admin.withdraws_perm === 1 &&
                <IconButton onClick={() => setSettingsDialogOpen(true)}>
                    <AppSettingsAltIcon />
                </IconButton>}
            <IconButton onClick={() => setToken(null)}>
                <LogoutIcon />
            </IconButton>
        </Box>
    )
}

export default Topbar