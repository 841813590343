import React from 'react'
import { Formik, useField, useFormikContext } from 'formik';
import * as yup from "yup";
import { Box, Button, Chip, Dialog, DialogContent, FormControl, InputLabel, Select, TextField, Typography, MenuItem, OutlinedInput, Checkbox } from '@mui/material';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import '../../../index.css';

function CampaignDialog({ targetCampaign, dialogOpen, handleClose, cards, handleFormSubmit }) {

    var initialValues = {
        title: targetCampaign?.title ? targetCampaign?.title : "",
        link: targetCampaign?.link ? targetCampaign?.link : "",
        description: targetCampaign?.description ? targetCampaign?.description : "",
        conditions: targetCampaign?.conditions ? targetCampaign?.conditions : "",
        companies: targetCampaign?.companies ? targetCampaign?.companies : "",
        category: targetCampaign?.category ? targetCampaign?.category : "",
        cards: targetCampaign?.cards ? targetCampaign.cards : [],
        type: targetCampaign?.type ? targetCampaign?.type : "",
        start_date: targetCampaign?.start_date ? new Date(targetCampaign?.start_date) : "",
        end_date: targetCampaign?.end_date ? new Date(targetCampaign?.end_date) : "",
    };

    var validationObject = {
        title: yup.string().required("zorunlu"),
        link: yup.string().required("zorunlu"),
        description: yup.string().required("zorunlu"),
        conditions: yup.string(),
        companies: yup.string(),
        category: yup.string(),
        cards: yup.array().of(
            yup.object({
                id: yup.number().required("zorunlu"),
                name: yup.string().required("zorunlu"),
                bank_name: yup.string().required("zorunlu")
            }).required("zorunlu")
        ).required("zorunlu"),
        type: yup.string(),
        start_date: yup.date(),
        end_date: yup.date().required("zorunlu")
    }

    const campaignSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    {targetCampaign ? "Kampanyayı Düzenle" : "Yeni Kampanya Ekle"}
                </Typography>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={campaignSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" width="400px" gridTemplateColumns="repeat(6, minmax(0, 1fr))" mx="75px" mb="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Başlık"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"title"}
                                        value={values.title}
                                        error={!!touched.title && !!errors.title}
                                        helperText={touched.title && errors.title}
                                        sx={{ gridColumn: "span 6", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="URL"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"link"}
                                        value={values.link}
                                        error={!!touched.link && !!errors.link}
                                        helperText={touched.link && errors.link}
                                        sx={{ gridColumn: "span 6", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Açıklama"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        multiline
                                        minRows={4}
                                        name={"description"}
                                        value={values.description}
                                        error={!!touched.description && !!errors.description}
                                        helperText={touched.description && errors.description}
                                        sx={{ gridColumn: "span 6", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Şartlar"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        multiline
                                        minRows={4}
                                        name={"conditions"}
                                        value={values.conditions}
                                        error={!!touched.conditions && !!errors.conditions}
                                        helperText={touched.conditions && errors.conditions}
                                        sx={{ gridColumn: "span 6", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Şirketler (Şirket1, Şirket2, Şirket3)"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"companies"}
                                        value={values.companies}
                                        error={!!touched.companies && !!errors.companies}
                                        helperText={touched.companies && errors.companies}
                                        sx={{ gridColumn: "span 6", marginTop: "20px" }}
                                    />

                                    <FormControl variant="filled" sx={{ gridColumn: "span 6", marginTop: "20px" }}>
                                        <InputLabel id="filter-label-cards">Kartlar</InputLabel>
                                        <Select
                                            labelId="filter-label-cards"
                                            label="Kartlar"
                                            onBlur={handleBlur}
                                            onChange={(event) => {
                                                const {
                                                    target: { value },
                                                } = event;
                                                var lastValue = value[value.length - 1];
                                                var existingIndex = value.findIndex((e) => e.id === lastValue.id);
                                                if (existingIndex !== -1 && existingIndex !== value.length - 1) {
                                                    value.splice(value.length - 1, 1);
                                                    value.splice(existingIndex, 1);
                                                }
                                                setFieldValue("cards", typeof value === 'string' ? value.split(',') : value)

                                            }}
                                            multiple
                                            name={"cards"}
                                            value={values.cards}
                                            // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                            renderValue={(selected) =>
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value) => {
                                                        if (value) {
                                                            return <Chip key={value.id} label={value.name + ` (${value.bank_name})`} />
                                                        } else return null
                                                    })}
                                                </Box>
                                            }
                                        >
                                            {cards.map(({ id, name, bank_name }) => (
                                                <MenuItem
                                                    key={id}
                                                    value={{ id: id, name: name, bank_name: bank_name }}

                                                >
                                                    <Checkbox checked={values.cards.findIndex((e) => e.id === id) != -1} />
                                                    {name + ` (${bank_name})`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Kategori"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"category"}
                                        value={values.category}
                                        error={!!touched.category && !!errors.category}
                                        helperText={touched.category && errors.category}
                                        sx={{ gridColumn: "span 3", marginTop: "20px" }}
                                    />
                                    <FormControl variant="filled" sx={{ gridColumn: "span 3", marginTop: "20px" }}>
                                        <InputLabel id="filter-label-type">Tip</InputLabel>
                                        <Select
                                            labelId="filter-label-type"
                                            label="Tip"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"type"}
                                            value={values.type}
                                            error={!!touched.type && !!errors.type}
                                        >
                                            <MenuItem value={""}>NONE</MenuItem>
                                            <MenuItem value={"puan"}>Puan</MenuItem>
                                            <MenuItem value={"taksit"}>Taksit</MenuItem>
                                            <MenuItem value={"indirim"}>indirim</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Box display="grid" gap="15px" rowGap={"5px"} gridTemplateColumns="repeat(6, minmax(0, 1fr))" gridColumn={"span 6"}>
                                        <Box sx={{ gridColumn: "span 3" }}>
                                            <InputLabel >Başlangıç Tarihi</InputLabel>
                                            {!!errors?.start_date && <InputLabel sx={{ color: "#fc6565" }}>{errors.start_date}</InputLabel>}
                                        </Box>
                                        <Box sx={{ gridColumn: "span 3" }}>
                                            <InputLabel >Bitiş Tarihi</InputLabel>
                                            {!!errors?.end_date && <InputLabel sx={{ color: "#fc6565" }}>{errors.end_date}</InputLabel>}
                                        </Box>
                                        <Box gridColumn={"span 3"}>
                                            <DatePickerField name="start_date" />
                                        </Box>
                                        <Box gridColumn={"span 3"}>
                                            <DatePickerField name="end_date" />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <Typography variant='h5'>Kaydet</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

const DatePickerField = ({ ...props }) => {
    const { setFieldValue, setFieldTouched, errors, touched } = useFormikContext();
    const [field] = useField(props);
    return (
        <DatePicker
            {...field}
            {...props}
            selected={(field.value && new Date(field.value)) || null}
            autoComplete="off"
            onChange={val => {
                setFieldValue(field.name, val);
                setFieldTouched(field.name);
            }}
        />
    );
};

export default CampaignDialog