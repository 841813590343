import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import React, { useState } from 'react'
import { tokens } from '../../../theme'
import MoreVertIcon from '@mui/icons-material/MoreVert';

function AdminBox({ admin, updateAdmin, updateAdminPassword, removeAdmin }) {
    const colors = tokens();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box width="300px">
            <Menu
                id="menu"
                aria-labelledby="open-menu-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    updateAdmin(admin);
                }}>
                    Düzenle
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose();
                    updateAdminPassword(admin);
                }}>
                    Şifre Değiştir
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose()
                    removeAdmin(admin);
                }}>
                    Sil
                </MenuItem>
            </Menu>
            <Box position="relative" bgcolor={colors.grey[900]} p="20px" borderRadius="10px">
                <Box position="absolute" top={15} right={15}>
                    <IconButton
                        id="open-menu-button"
                        aria-controls={open ? 'menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}>
                        <MoreVertIcon sx={{ scale: "1.2" }} />
                    </IconButton>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant='h3' fontWeight="600">
                        {admin.username ? admin.username : "-"}
                    </Typography>
                </Box>
                <Box component="hr" my="20px" border={"1px solid " + colors.grey[500]} />
                <Box mt="10px">
                    <Box display="flex" justifyContent="space-between" mt="10px">
                        <Box>
                            <Typography variant='h6' ><b style={{ marginRight: "5px" }}>-Kullanıcı İzni:</b> {admin.users_perm ? "Evet" : "Hayır"}</Typography>
                            <Typography variant='h6' ><b style={{ marginRight: "5px" }}>-Kampanya İzni:</b> {admin.campaigns_perm ? "Evet" : "Hayır"}</Typography>
                            <Typography variant='h6' ><b style={{ marginRight: "5px" }}>-İletişim İzni:</b> {admin.contact_messages_perm ? "Evet" : "Hayır"}</Typography>
                            <Typography variant='h6' ><b style={{ marginRight: "5px" }}>-Bildirim İzni:</b> {admin.notifications_perm ? "Evet" : "Hayır"}</Typography>
                            <Typography variant='h6' ><b style={{ marginRight: "5px" }}>-Yönetici İzni:</b> {admin.admins_perm ? "Evet" : "Hayır"}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default AdminBox