import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';

function CompanyDialog({ targetCompany, dialogOpen, handleClose, handleFormSubmit }) {

    var initialValues = {
        cid: targetCompany?.cid ? targetCompany?.cid : "",
        name: targetCompany?.name ? targetCompany?.name : "",
        category: targetCompany?.category ? targetCompany?.category : "",
        latitude: targetCompany?.latitude ? targetCompany?.latitude : 0,
        longitude: targetCompany?.longitude ? targetCompany?.longitude : 0,
        address: targetCompany?.address ? targetCompany?.address : "",
        website: targetCompany?.website ? targetCompany?.website : "",
        phone_number: targetCompany?.phone_number ? targetCompany?.phone_number : "",
    };

    var validationObject = {
        cid: yup.string().required("zorunlu"),
        name: yup.string().required("zorunlu"),
        category: yup.string().required("zorunlu"),
        latitude: yup.number(),
        longitude: yup.number(),
        address: yup.string(),
        website: yup.string(),
        phone_number: yup.string()
    }

    const companySchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    {targetCompany ? "Şirketi Düzenle" : "Yeni Şirket Ekle"}
                </Typography>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={companySchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" width="400px" gridTemplateColumns="repeat(6, minmax(0, 1fr))" mx="75px" mb="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="CID"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"cid"}
                                        value={values.cid}
                                        error={!!touched.cid && !!errors.cid}
                                        helperText={touched.cid && errors.cid}
                                        sx={{ gridColumn: "span 3", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="İsim"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"name"}
                                        value={values.name}
                                        error={!!touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                        sx={{ gridColumn: "span 3", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Kategori"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"category"}
                                        value={values.category}
                                        error={!!touched.category && !!errors.category}
                                        helperText={touched.category && errors.category}
                                        sx={{ gridColumn: "span 3", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Telefon"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"phone_number"}
                                        value={values.phone_number}
                                        error={!!touched.phone_number && !!errors.phone_number}
                                        helperText={touched.phone_number && errors.phone_number}
                                        sx={{ gridColumn: "span 3", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Latitude"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"latitude"}
                                        value={values.latitude}
                                        error={!!touched.latitude && !!errors.latitude}
                                        helperText={touched.latitude && errors.latitude}
                                        sx={{ gridColumn: "span 3", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Longitude"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"longitude"}
                                        value={values.longitude}
                                        error={!!touched.longitude && !!errors.longitude}
                                        helperText={touched.longitude && errors.longitude}
                                        sx={{ gridColumn: "span 3", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Adres"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"address"}
                                        value={values.address}
                                        error={!!touched.address && !!errors.address}
                                        helperText={touched.address && errors.address}
                                        sx={{ gridColumn: "span 3", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Website"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"website"}
                                        value={values.website}
                                        error={!!touched.website && !!errors.website}
                                        helperText={touched.website && errors.website}
                                        sx={{ gridColumn: "span 3", marginTop: "20px" }}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <Typography variant='h5'>Kaydet</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default CompanyDialog