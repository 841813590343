import { Box, Button, FormControl, IconButton, InputLabel, Menu, MenuItem, OutlinedInput } from '@mui/material'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import GetAppIcon from '@mui/icons-material/GetApp';
import { tokens } from '../../theme';
import Header from '../../global/Header';
import CampaignDialog from './CampaignDialog';
import * as XLSX from 'xlsx';
import { AdminContext } from '../../context/AdminContext';

function CampaginsPage() {
    const { token } = useContext(AdminContext);
    const colors = tokens();
    const [isLoading, setLoading] = useState(true);
    const [searchKey, setSearchKey] = useState("");
    const [searchId, setSearchId] = useState(null);
    const [orderModel, setOrderModel] = useState(null);
    const [rowCount, setRowCount] = useState(0);
    const [campaigns, setCampaigns] = useState([]);
    const [cards, setCards] = useState([]);
    const [targetCampaign, setTargetCampaign] = useState(null);
    const [editCampaignDialogOpen, setEditCampaignDialogOpen] = useState(false);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });

    const updateCampaign = async (campaignId, campaign, handleCards) => {
        var cardIds = [];
        if (handleCards && campaign.cards && campaign.cards.length > 0) {
            cardIds = campaign.cards.map(card => card.id);
        }
        if (!campaignId) {
            await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/createCampaign?token=${token}`, {
                ...campaign, ...(handleCards ? { cards: cardIds } : {})
            }).catch((err) => {
                console.log("err: " + err);
            })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        campaign.id = response.data.campaignId;
                        setCampaigns(prev => [...prev, campaign]);
                        if (editCampaignDialogOpen) {
                            setEditCampaignDialogOpen(false);
                        }
                        setTargetCampaign(null);
                    }
                });
        } else {
            var campaignIx = campaigns.findIndex(c => c.id === campaignId);
            await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateCampaign?token=${token}&campaignId=${campaignId}&handleCards=${handleCards ? 1 : 0}`, {
                campaign: { ...campaign, ...(handleCards ? { cards: cardIds } : {}) }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        if (campaignIx !== null && campaignIx !== undefined && campaignIx !== -1) {
                            setCampaigns(prev => {
                                var newCampaigns = [...prev];
                                newCampaigns[campaignIx] = { ...newCampaigns[campaignIx], ...campaign };
                                return newCampaigns;
                            })
                        }
                        if (editCampaignDialogOpen) {
                            setEditCampaignDialogOpen(false);
                        }
                        setTargetCampaign(null);
                    }
                });
        }
    }

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 25
        }, {
            field: 'title',
            headerName: 'Başlık'
        }, {
            field: 'link',
            headerName: 'URL'
        }, {
            field: 'description',
            headerName: 'Açıklama',
            minWidth: 200
        }, {
            field: 'conditions',
            headerName: 'Şartlar',
            minWidth: 200
        }, {
            field: 'companies',
            headerName: 'Firmalar',
            width: 75
        }, {
            field: 'category',
            headerName: 'Kategori',
            width: 75
        }, {
            field: 'type',
            headerName: 'Tip',
            width: 75
        }, {
            field: 'updatable',
            headerName: 'Otomatik Güncelleme',
            type: "boolean",
            width: 75
        }, {
            field: 'active',
            headerName: 'Aktif',
            type: "boolean",
            width: 75
        }, {
            field: "start_date", headerName: "Başlangıç",
            renderCell: ({ row: { end_date } }) => {
                if (end_date) {
                    return <p style={{ textAlign: "center" }}>{new Date(end_date).toLocaleDateString()}<br />{new Date(end_date).toLocaleTimeString()}</p>
                } else {
                    return <p style={{ textAlign: "center" }}>-</p>
                }
            }
        }, {
            field: "end_date", headerName: "Bitiş",
            renderCell: ({ row: { end_date } }) => {
                return <p style={{ textAlign: "center" }}>{new Date(end_date).toLocaleDateString()}<br />{new Date(end_date).toLocaleTimeString()}</p>
            }
        }, {
            field: "updated_date", headerName: "Güncellenme Tarihi",
            renderCell: ({ row: { updated_date } }) => {
                return <p style={{ textAlign: "center" }}>{new Date(updated_date).toLocaleDateString()}<br />{new Date(updated_date).toLocaleTimeString()}</p>
            }
        }, {
            field: "created_date", headerName: "Oluşturulma Tarihi",
            renderCell: ({ row: { created_date } }) => {
                return <p style={{ textAlign: "center" }}>{new Date(created_date).toLocaleDateString()}<br />{new Date(created_date).toLocaleTimeString()}</p>
            }
        },
        {
            field: 'actions',
            type: 'actions',
            sortable: false,
            width: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<PersonIcon />}
                    label="Kampanyayı Düzenle"
                    onClick={() => setTargetCampaign(params.row)}
                    showInMenu
                />,
                ...(params.row.active === 0 ?
                    [<GridActionsCellItem
                        icon={<WorkspacePremiumIcon />}
                        label="Aktifleştir"
                        onClick={() => updateCampaign(params.row.id, { active: 1 }, false)}
                        showInMenu
                    />] :
                    [
                        <GridActionsCellItem
                            icon={<WorkspacePremiumIcon />}
                            label="Pasif yap"
                            onClick={() => updateCampaign(params.row.id, { active: 0 }, false)}
                            showInMenu
                        />
                    ])
                ,
                ...(params.row.updatable === 0 ?
                    [<GridActionsCellItem
                        icon={<WorkspacePremiumIcon />}
                        label="Otomatik Güncellemeyi Aç"
                        onClick={() => updateCampaign(params.row.id, { updatable: 1 }, false)}
                        showInMenu
                    />] :
                    [
                        <GridActionsCellItem
                            icon={<WorkspacePremiumIcon />}
                            label="Otomatik Güncellemeyi Kapat"
                            onClick={() => updateCampaign(params.row.id, { updatable: 0 }, false)}
                            showInMenu
                        />
                    ])
                ,
            ],
        }
    ];

    useEffect(() => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getCampaignsWithFilter?token=${token}&startIndex=${paginationModel.pageSize * paginationModel.page}&limit=${paginationModel.pageSize}${searchKey ? `&searchKey=${searchKey}` : ""}${searchId ? `&searchId=${searchId}` : ""}${orderModel ? `&orderField=${orderModel.field}&orderType=${orderModel.sort}` : ""}`)
            .catch((err) => {
                setCampaigns([]);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setCampaigns(response.data.campaigns);
                    setRowCount((prevRowCount) => response.data.rowCount !== undefined ? response.data.rowCount : prevRowCount);
                }
                setLoading(false);
            });
    }, [paginationModel]);

    useEffect(() => {
        if (targetCampaign) {
            if (targetCampaign.cardsFetched) {
                setEditCampaignDialogOpen(true);
            } else {
                axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getCampaignCards?token=${token}&campaignId=${targetCampaign.id}`)
                    .catch((err) => {
                        console.log("err: " + err);
                        setTargetCampaign((prev) => { return { ...prev, cards: [], cardsFetched: true } });
                    })
                    .then((response) => {
                        if (response && response.data.success === true && response.data.status === 200) {
                            setTargetCampaign((prev) => { return { ...prev, cards: response.data.cards, cardsFetched: true } });
                        }
                    });
            }
        }
    }, [targetCampaign]);

    const handleEditCampaignDialogClose = () => {
        setTargetCampaign(null);
        setEditCampaignDialogOpen(false);
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getCards?token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setCards(response.data.cards);
                }
            });
    }, []);

    const exportCampaigns = (fileExtention) => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getAllCampaignsToExport?token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    const worksheetApprovers = XLSX.utils.json_to_sheet(response.data.campaigns);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheetApprovers, "Campaings");
                    XLSX.writeFile(workbook, `Campaigns-${(new Date()).toISOString()}.${fileExtention}`);
                }
            });
    }


    //export menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ p: "75px" }}>
            {cards && cards.length > 0 &&
                <CampaignDialog dialogOpen={editCampaignDialogOpen} handleClose={handleEditCampaignDialogClose} targetCampaign={targetCampaign} cards={cards} handleFormSubmit={(values) => updateCampaign(targetCampaign?.id, values, true)} />
            }
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => { exportCampaigns("xlsx"); handleClose(); }}>Campaigns(.xlsx)</MenuItem>
                <MenuItem onClick={() => { exportCampaigns("csv"); handleClose(); }}>Campaigns(.csv)</MenuItem>
            </Menu>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" gap="20px">
                    <Header title="Kampanyalar" subtitle="Bu sayfadan kampanyaları görebilir ve yönetebilirsiniz." />
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="search-box">Ara</InputLabel>
                        <OutlinedInput
                            id="search-box"
                            type='text'
                            onChange={(e) => {
                                setSearchKey(e.target.value ? e.target.value : "");
                            }}
                            label="Ara"
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    setPaginationModel(prev => { return { ...prev, page: 0 } })
                                } else {
                                    return;
                                }
                            }}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="search-box">Ara(Id)</InputLabel>
                        <OutlinedInput
                            id="search-box"
                            type='number'
                            onChange={(e) => {
                                setSearchId(e.target.value ? e.target.value : "");
                            }}
                            label="Ara(Id)"
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    setPaginationModel(prev => { return { ...prev, page: 0 } })
                                } else {
                                    return;
                                }
                            }}
                        />
                    </FormControl>
                    <IconButton
                        aria-label="search button"
                        sx={{ background: colors.grey[900], borderRadius: "10px", padding: "12px" }}
                        onClick={() => { setPaginationModel(prev => { return { ...prev, page: 0 } }) }}
                        edge="end"
                    >
                        <SearchIcon />
                    </IconButton>

                    <Button component="label" variant='contained' color='success' sx={{ ml: "20px" }} onClick={() => setEditCampaignDialogOpen(true)}>
                        Yeni Kampanya Ekle
                    </Button>

                </Box>
                <IconButton
                    aria-label="export campaigns button"
                    sx={{ background: colors.grey[900], borderRadius: "10px", padding: "12px", mr: "5px" }}
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    edge="end"
                >
                    <GetAppIcon />
                </IconButton>
            </Box>
            <DataGrid
                rows={campaigns}
                columns={columns}
                rowCount={rowCount}
                loading={isLoading}
                pageSizeOptions={[10, 25, 50, 75, 100]}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                sortingMode='server'
                onSortModelChange={(model) => {
                    setOrderModel((prev) => model && model.length > 0 ? model[0] : null);
                    setPaginationModel(prev => { return { ...prev, page: 0 } });
                }}
                onRowDoubleClick={(params) => {
                    setTargetCampaign(params.row)
                }}
                showCellVerticalBorder
                showColumnVerticalBorder
                disableColumnFilter
                sx={{
                    ".MuiDataGrid-columnHeaders": {
                        background: colors.grey[900]
                    },
                    ".MuiDataGrid-cell:focus-within": {
                        outline: "none !important;"
                    }
                }}
            />
        </Box>
    )
}

export default CampaginsPage